<template>
  <div>
    <header-slot
      v-if="moduleId !== 5"
      :clients-search="true"
      :global-search="true"
    >
      <template #actions>
        <b-row class="w-100">
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info">GLOBAL SEARCH</b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchText"
                @keyup.enter="search"
                placeholder="Search by Name, Account or the last 4 digits of Phone Number"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="search">
                  <feather-icon icon="SearchIcon"></feather-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <b-row>
      <b-col>
        <b-nav card-header pills class="m-0">
          <b-nav-item
            :to="{ name: $route.matched[metaIndex].meta.routePending }"
            exact
            exact-active-class="active"
            :active="isPendingTab"
            :link-classes="['px-3', bgTabsNavs]"
            >PENDING
            <span v-if="cPendingWp > 0 && moduleId == 28" class="ml-1">
              <feather-icon
                icon
                :badge="cPendingWp > 99 ? '99+' : cPendingWp"
                badge-classes="badge-danger"
              />
            </span>
          </b-nav-item>

          <b-nav-item
            :to="{ name: $route.matched[metaIndex].meta.routeToRevision }"
            exact
            exact-active-class="active"
            :active="isToRevisionTab"
            :link-classes="['px-3', bgTabsNavs]"
            >TO REVISION
            <span
              v-if="cToRevisionWp > 0 && moduleId == 28 && validationCOunter"
              class="ml-1"
            >
              <feather-icon
                icon
                :badge="cToRevisionWp > 99 ? '99+' : cToRevisionWp"
                badge-classes="badge-danger"
              />
            </span>
          </b-nav-item>

          <b-nav-item
            :to="{ name: $route.matched[metaIndex].meta.routeCompleted }"
            exact
            exact-active-class="active"
            :active="isCompleteTab"
            :link-classes="['px-3', bgTabsNavs]"
            >COMPLETED
            <span v-if="G_COMPLETE_WORKPLAN > 0" class="ml-1">
              <feather-icon
                icon
                :badge="G_COMPLETE_WORKPLAN > 99 ? '99+' : G_COMPLETE_WORKPLAN"
                badge-classes="badge-danger"
              /> </span
          ></b-nav-item>

          <b-nav-item
            v-if="moduleId == 28"
            :to="{ name: $route.matched[metaIndex].meta.routeDeleted }"
            exact
            exact-active-class="active"
            :active="isDeleteTab"
            :link-classes="['px-3', bgTabsNavs]"
            >DELETED
          </b-nav-item>
        </b-nav>
        <b-card
          no-body
          class="border-top-primary border-3 border-table-radius px-0"
        >
          <router-view :key="$route.name" />
        </b-card>
      </b-col>
    </b-row>

    <modal-global-search
      v-if="showModalGlobalSearch"
      :search-text="searchText"
      :role-id="currentUser.role_id"
      :program-id="programId"
      @closeModal="showModalGlobalSearch = false"
    />
  </div>
</template>

<script>
// STORE
import { mapGetters, mapActions } from "vuex";

// MODALS
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

export default {
  components: {
    ModalGlobalSearch,
  },
  data() {
    return {
      searchText: "",
      showModalGlobalSearch: false,
      programId: 7,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      cPendingWp: "RequestWorkPlanStore/G_PENDING_WORKPLAN",
      cToRevisionWp: "RequestWorkPlanStore/G_TOREVISION_WORKPLAN",
      G_COMPLETE_WORKPLAN: "RequestWorkPlanStore/G_COMPLETE_WORKPLAN",
    }),
    metaIndex() {
      return this.moduleId == 5 ? 2 : 1;
    },
    isPendingTab() {
      const { routePending } = this.$route.matched[this.metaIndex].meta;
      return routePending == this.$route.name;
    },
    isToRevisionTab() {
      const { routeToRevision } = this.$route.matched[this.metaIndex].meta;
      return routeToRevision == this.$route.name;
    },
    isCompleteTab() {
      const { routeCompleteTab } = this.$route.matched[this.metaIndex].meta;
      return routeCompleteTab == this.$route.name;
    },
    isDeleteTab() {
      const { routeDeleted } = this.$route.matched[this.metaIndex].meta;
      return routeDeleted == this.$route.name;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    validationCOunter() {
      return (
        this.isCeo ||
        this.isSupervisor ||
        this.currentUser.role_id == 20 ||
        this.currentUser.role_id == 22
      );
    },
  },
  async created() {
    await this.A_REQUEST_WORKPLAN_COUNTERS(this.moduleId);
  },
  methods: {
    ...mapActions({
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
      A_GLOBAL_SEARCH_CLIENTS: "clients-store/A_GLOBAL_SEARCH_CLIENTS",
    }),
    search() {
      this.showModalGlobalSearch = true;
    },
  },
};
</script>
